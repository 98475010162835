require('./bootstrap');


if (!sessionStorage.getItem('logo_shown')){
    $('.loader-img').addClass("show");
}

$(document).ready(function(){
    "use strict";

    if (sessionStorage.getItem('logo_shown')){
        $( ".loading-overlay" ).addClass('out');
    }

    if (!sessionStorage.getItem('logo_shown')){
        setTimeout(showpanel, 1300);
    }
    sessionStorage.setItem('logo_shown', true);

    function showpanel() {
        $('.loading-overlay').addClass("move-up");
    }



    if ($(this).scrollTop() > 125) {
        $('.mainnav').addClass('scrolled');
    } else {
        $('.mainnav').removeClass('scrolled');
    }

    $(window).scroll(function(){
        if ($(this).scrollTop() > 125) {
            $('.mainnav').addClass('scrolled');
        } else {
            $('.mainnav').removeClass('scrolled');
        }
    });

    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('#nav-links').toggleClass("show");
        $('#mainnav').toggleClass("menu-open");
        $('body').toggleClass("menu-open");
        $('#bg-overlay').toggleClass("show");
        $(".nav-dropdown").hide();
        $('.nav-logo-headline').toggleClass("text-white");
    });
    $("#bg-overlay").click(function(){
        $(this).removeClass("show");
        $('#nav-links').removeClass("show");
        $('.hamburger').removeClass("is-active");
        $('#mainnav').removeClass("menu-open");
        $('body').toggleClass("menu-open");
        $(".nav-dropdown").hide();
    });
});
